/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
var l;
function o(o) {
  return "global" === o ? l.Global : l.Local;
}
function a(o) {
  return o === l.Global ? "global" : "local";
}
!function (l) {
  l[l.Global = 1] = "Global", l[l.Local = 2] = "Local";
}(l || (l = {}));
export { l as ViewingMode, a as stringFromViewingMode, o as viewingModeFromString };